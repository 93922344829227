import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import subscriptionResponse from '../../shared/routes/subscriptionsRoutes'
import * as moment from 'moment'

class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            loggedIn: true, //todo: get from a central source
            hasSubscription: true, //todo: get from a central source
            apiResponse: null
        }
        subscriptionResponse().then(async (res)=>{
            this.setState({ apiResponse: res })
        })
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        if (!this.state.loggedIn || !this.state.hasSubscription) {
            this.setState({
                redirect: "/subscribe/choose"
            });
        }

        let first = true;

        return (
            <div className='text-center'>
                <link rel="stylesheet" type="text/css" href="/styles/subscribe.css?version=8-28-2021" />
                {this.props.includeHeader && <>
                    <h1 className='text-center'>Extend Subscriptions</h1>
                    <p className='text-center'>
                        Please select the subscription you would like to extend.
                    </p>
                </>}
                <div className="table-responsive">
                    <p>Total Student Licenses: {this.state.apiResponse?.totalLicenses.math} Math, {this.state.apiResponse?.totalLicenses.reading} Reading, {this.state.apiResponse?.totalLicenses.emergentReader} Emergent Reader</p>
                    <p className="spacing-line"></p>
                    <h3 className="subheader-text">Valid Subscriptions</h3>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Math Licenses</th>
                                <th>Reading Licenses</th>
                                <th>Emergent Reader Licenses</th>
                                <th>Start Date</th>
                                <th>Expiration Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.apiResponse?.validSubscriptions?.map(((validSub, index) => {
                                // first valid subscription (expiring soonest) that is not a trial has red background
                                let isRed = false;
                                if (first && validSub.future == null) {
                                    isRed = true;
                                    first = false;
                                }

                                const extendSearchParams = new URLSearchParams({
                                    math: validSub.mathLicenses,
                                    reading: validSub.readingLicenses,
                                    emergentReader: validSub.emergentReaderLicenses,
                                    start: validSub.start,
                                    exp: validSub.expiration,
                                    created: validSub.created
                                })
                                let actionCol = (
                                    <td>
                                        <Link to={`/subscribe/extend?${extendSearchParams}`}>
                                            <div className="btn btn-secondary btn-md">Extend</div>
                                        </Link>
                                    </td>
                                )

                                if (validSub.trial) {
                                    actionCol = (
                                        <td>
                                            <p className="extend-p">Trial Subscription</p>
                                        </td>
                                    )
                                }
                                //todo: add label for extended subscriptions
                                if (validSub.future != null) {
                                    let targetID = "extended-" + index
                                    return (
                                        <React.Fragment>
                                            <tr data-toggle="collapse" data-target={"#" + targetID} className="cursor-pointer accordion-toggle collapsed active">
                                                <td class="align-middle">{validSub.mathLicenses}</td>
                                                <td class="align-middle">{validSub.readingLicenses}</td>
                                                <td class="align-middle">{validSub.emergentReaderLicenses}</td>
                                                <td class="align-middle">{moment(validSub.start).format("MMM D, YYYY")}</td>
                                                <td class="align-middle">{moment(validSub.expiration).format("MMM D, YYYY")}</td>
                                                <td>
                                                    <p className="extend-p">Extended Subscription <i className="fas" /></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6" className="nopadding">
                                                    <div className="accordian-body collapse" id={targetID}>
                                                        <table className="table" colspan="6">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <td>Future Math Licenses</td>
                                                                    <td>Future Reading Licenses</td>
                                                                    <td>Future Emergent Reader Licenses</td>
                                                                    <td>Start Date</td>
                                                                    <td>Expiration</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="align-middle">{validSub.future.mathLicenses}</td>
                                                                    <td className="align-middle">{validSub.future.readingLicenses}</td>
                                                                    <td className="align-middle">{validSub.future.emergentReaderLicenses}</td>
                                                                    <td className="align-middle">{moment(validSub.future.start).format("MMM D, YYYY")}</td>
                                                                    <td className="align-middle">{moment(validSub.future.expiration).format("MMM D, YYYY")}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                }
                                return (
                                    <tr class={isRed ? "danger" : ""} >
                                        <td className="align-middle">{validSub.mathLicenses}</td>
                                        <td className="align-middle">{validSub.readingLicenses}</td>
                                        <td className="align-middle">{validSub.emergentReaderLicenses}</td>
                                        <td className="align-middle">{moment(validSub.start).format("MMM D, YYYY")}</td>
                                        <td className="align-middle">{moment(validSub.expiration).format("MMM D, YYYY")}</td>
                                        {actionCol}
                                    </tr>
                                )
                            }))}
                        </tbody>
                    </table>
                </div>
                {this.state.apiResponse?.expiredSubscriptions?.length > 0 ? 
                    <div className="table-responsive text-center">
                        <hr className='pb-4'/>
                        <h3 className="subheader-text">Expired Subscriptions</h3>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Math Licenses</th>
                                    <th>Reading Licenses</th>
                                    <th>Emergent Reader Licenses</th>
                                    <th>Start Date</th>
                                    <th>Expiration Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.apiResponse?.expiredSubscriptions?.map((sub => {
                                    const extendSearchParams = new URLSearchParams({
                                        math: sub.mathLicenses,
                                        reading: sub.readingLicenses,
                                        emergentReader: sub.emergentReaderLicenses,
                                        start: sub.start,
                                        exp: sub.expiration,
                                        created: sub.created
                                    })
                                    let actionCol = (
                                        <td>
                                            <Link to={`/subscribe/extend?${extendSearchParams}`}>
                                                <div className="btn btn-secondary btn-md">Extend</div>
                                            </Link>
                                        </td>
                                    )
                                    if (sub.trial) {
                                        actionCol = (
                                            <td>
                                                <p className="extend-p">Trial Subscription</p>
                                            </td>
                                        )
                                    }
                                    if (sub.extended === "Yes") {
                                        actionCol = (
                                            <td>
                                                <p className="extend-p">Extended Subscription</p>
                                            </td>
                                        )
                                    }
                                    return (
                                        <tr>
                                            <td className="align-middle">{sub.mathLicenses}</td>
                                            <td className="align-middle">{sub.readingLicenses}</td>
                                            <td className="align-middle">{sub.emergentReaderLicenses}</td>
                                            <td className="align-middle">{moment(sub.start).format("MMM D, YYYY")}</td>
                                            <td className="align-middle">{moment(sub.expiration).format("MMM D, YYYY")}</td>
                                            {actionCol}
                                        </tr>
                                    )
                                }))}
                            </tbody>
                        </table>
                    </div>
            : ""}

                <p className="spacing-line"></p>
                <p className="small">Extending a subscription does not change the total students until a subscription has expired.</p>
            </div>
        );
    }

}

export default Summary;
